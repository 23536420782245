import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Image from "gatsby-image"

require(`katex/dist/katex.min.css`)
require(`./bibtex.css`)
require(`./blogpost.css`)

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const images = {};
    for (let image in this.props.data.images.edges) {
      images[this.props.data.images.edges[image].node.relativePath] = this.props.data.images.edges[image].node
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1 className={'blog-title'} style={{ maxWidth: `800px` }}>{post.frontmatter.title}</h1>

        {post.frontmatter.featured_image != null &&
          <div style={{ width: "98.5vw", marginLeft: '-50vw', marginRight: '-50vw', position: 'relative', right: '50%', left: '50%', background: '#eee', marginTop: '30px', marginBottom: '30px', overflow: 'none' }}>
            <Image style={{ maxHeight: '540px' }} fluid={images[post.frontmatter.featured_image.relativePath].childImageSharp.fluid} />
          </div>
        }

        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(1),
            color: "#555"
          }}
        >
          {post.frontmatter.date}
        </p>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogpostQuery($id: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featured_image {publicURL, relativePath}
      }
    }
    images: allFile(filter: {
      absolutePath: { regex: "/content/blog/(.*)/(.*).[png|jpg|jpeg]/" }
    }) {
      edges {
        node {
          relativePath
          childImageSharp  {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
